import React from "react"
import headerLogo from "../../images/Hey-Carter-Logo-about.png"

const CSHeader = ({costingSheet}) => {
    return(
        <header style={headerStyles}>
            <div style={logoStyles}>
                <img width="98" height="85" src={headerLogo} alt="Carter Logo" />                            
            </div>
            <div style={logoTextStyle}>
                <div>CARTER DEALERSHIPS</div>
            </div>
            <div style={headerInfoStyle}>
                <div>COSTING SHEET</div>
                <div>{`${costingSheet.CS_UID} - ${costingSheet.Status}`}</div>
            </div>
        </header>
    )
}

export default CSHeader

const headerStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
}

const logoStyles = {
       
}

const logoTextStyle = {
    fontSize: "1.3em",
    
}

const headerInfoStyle = {
    fontSize: "1.2em",
    fontWeight: "bold"
}