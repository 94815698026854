import React from "react";
import { currencyFormat } from "./../methods/CurrencyFormat";

const CSCostingInfo = ({ costingSheet: cs }) => {
  return (
    <div>
      <table style={tableStyles}>
        <thead>
          <tr>
            <th style={thStyles}>Costing Calculation Details</th>
            <th style={thStyles}>Selling Price (Excl VAT)</th>
            <th style={thStyles}>Cost Price (Excl VAT)</th>
            <th style={thStyles}>Profit (Excl VAT)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tdDescStyles}>Vehicle Price</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Base_Vehicle_Price, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Base_Vehicle_Cost, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Base_Vehicle_Sub_Total, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles} colSpan="3">
              <table style={extrasContainer}>
                <tbody>
                  {cs.extras.map(({ CSE_Name, CSE_Price, CSE_Cost }) => (
                    <tr key={CSE_Name}>
                      <td style={extraNameTDStyle}>{CSE_Name}</td>
                      <td style={extraTDStyle}>
                        {currencyFormat(CSE_Price, 2)}
                      </td>
                      <td style={extraTDStyle}>
                        {currencyFormat(CSE_Cost, 2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>

            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Optional_Extras_Total, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Delivery Fee</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Delivery_Fee_Price, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Delivery_Fee_Cost, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Delivery_Fee_Sub_Total, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Fuel Cost</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Fuel_Cost_Excl_VAT, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Fuel_Sub_Total_Excl_VAT, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Repairs / Recon Price</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Repairs_Recon_Price, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Repairs_Recon_Cost, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Repairs_Recon_Sub_Total, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Valet</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>{currencyFormat(cs.Valet_Cost, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Valet_Sub_Total, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Bank Subvention (Full Amount)</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Bank_Subvention_Cost, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Subvention_Sub_Total, 2)}
            </td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={tdDescStyles}>On The Road Fees</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.On_The_Road_Fee_Price, 2)}
            </td>
            <td style={tdCurrencyStyle} rowSpan="2">
              {currencyFormat(cs.Provision_Cost, 2)}
            </td>
            <td style={tdCurrencyStyle} rowSpan="4">
              {currencyFormat(cs.Provision_Sub_Total)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Licence And Registration</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Licence_And_Reg_Fee_Price, 2)}
            </td>
            {/* <td style={tdCurrencyStyle}>
                            {currencyFormat(0,2)}
                        </td>                     */}
          </tr>
          <tr>
            <td style={tdDescStyles}>Roadworthy</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Roadworthy_Cost)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>PDI</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>{currencyFormat(cs.PDI_Cost)}</td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Smash {`&`} Grab</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Smash_And_Grab_Price)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Smash_And_Grab_Cost)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Smash_And_Grab_Sub_Total)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Spotters Fee</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Spotters_Fee_Cost, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Spotters_Fee_Sub_Total, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Gifts (Full Amount)</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>{currencyFormat(cs.Gift_Cost, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Gift_Sub_Total, 2)}
            </td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Help Deposit</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Help_Deposit, 2)}
            </td>
            <td style={tdCurrencyStyle} rowSpan="5">
              {currencyFormat(cs.Deal_Assistance_Sub_Total, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Discount</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>{currencyFormat(cs.Discount, 2)}</td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Rebate</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>{currencyFormat(cs.Rebate, 2)}</td>
          </tr>
          <tr>
            <td style={tdDescStyles}>MAC</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>{currencyFormat(cs.MAC, 2)}</td>
          </tr>
          <tr>
            <td style={tdDescStyles}>SAM Assistance</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.SAM_Assistance, 2)}
            </td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Non Trade-In Cash Back (Full Amount)</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Non_Trade_In_Cash_Back_Full_Amount, 2)}
            </td>
            <td style={tdCurrencyStyle} rowSpan="2">
              {currencyFormat(cs.Non_Trade_In_Cash_Back_Sub_Total_Exc, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>
              Non Trade-In Cash Back To 3rd Party (Full Amount)
            </td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Non_Trade_In_Cash_To_3rd_Party_Full_Amount, 2)}
            </td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Trade-In Fair Value</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Trade_In_Fair_Value, 2)}
            </td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle} rowSpan="8">
              {currencyFormat(cs.TradeIn_Net, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Trade-In Settlement</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Trade_In_Settlement, 2)}
            </td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Trade-In Shortfall / Surplus</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Trade_In_Surplus_Shortfall, 2)}
            </td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Over Allowance</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Over_Allowance, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Customer Contribution To Shortfall</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Customer_Contribution_To_Shortfall, 2)}
            </td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Trade-In Cash As Deposit</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Trade_In_Cash_As_Deposit, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Customer Cashback On Trade-In</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>{currencyFormat(cs.Cash_Back, 2)}</td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Trade-In Settlement Adjustment</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Settlement_Adjustment_Excl_VAT, 2)}
            </td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Total Recon Cost</td>
            <td style={tdCurrencyStyle}>{currencyFormat(0, 2)}</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Total_Recon_Cost_Full_Amount, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Recon_Items_Sub_Total_Excl_VAT, 2)}
            </td>
          </tr>
          <tr style={{ height: "10px" }}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={tdTotalsStyles} colSpan="3">
              First Gross Profit
            </td>
            <td style={tdCurrencyStyle}>
              <strong>{currencyFormat(cs.FirstGross_Profit, 2)}</strong>
            </td>
          </tr>
          <tr>
            <td style={tdDescStyles} colSpan="3">
              <table style={extrasContainer}>
                <tbody>
                  {cs.vaps.map(({ VAP_Name, VAP_Price, VAP_Cost }) => (
                    <tr key={VAP_Name}>
                      <td style={extraNameTDStyle}>{VAP_Name}</td>
                      <td style={extraTDStyle}>
                        {currencyFormat(VAP_Price, 2)}
                      </td>
                      <td style={extraTDStyle}>
                        {currencyFormat(VAP_Cost, 2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>

            <td style={tdCurrencyStyle}>{currencyFormat(cs.VAPs_Total, 2)}</td>
          </tr>
          <tr>
            <td style={tdDescStyles} colSpan="3">
              DIC
            </td>
            <td style={tdCurrencyStyle}>{currencyFormat(cs.DIC, 2)}</td>
          </tr>
          <tr>
            <td style={tdTotalsStyles} colSpan="3">
              Second Gross Profit
            </td>
            <td style={tdCurrencyStyle}>
              <strong>{currencyFormat(cs.SecondGross_Profit, 2)}</strong>
            </td>
          </tr>
          <tr>
            <td style={tdTotalsStyles} colSpan="3">
              Total Gross Profit
            </td>
            <td style={tdCurrencyStyle}>
              <strong>{currencyFormat(cs.TotalGross_Profit, 2)}</strong>
            </td>
          </tr>
          <tr>
            <td style={tdTotalPercStyles} colSpan="3">
              First Gross Percentage
            </td>
            <td style={tdCurrencyStyle}>{`${cs.FirstGross_Percentage} %`}</td>
          </tr>
          <tr>
            <td style={tdTotalPercStyles} colSpan="3">
              Second Gross Percentage
            </td>
            <td style={tdCurrencyStyle}>{`${cs.SecondGross_Percentage} %`}</td>
          </tr>
          <tr>
            <td style={tdTotalPercStyles} colSpan="3">
              Total Gross Percentage
            </td>
            <td style={tdCurrencyStyle}>{`${cs.TotalGross_Percentage} %`}</td>
          </tr>
          <tr>
            <td style={tdDescStyles}>Warranty</td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Warranty_Price, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Warranty_Cost, 2)}
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Warranty_SubTotal, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdTotalsStyles} colSpan="3">
              Total With Warranty
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Total_With_Warranty, 2)}
            </td>
          </tr>
          <tr>
            <td style={tdTotalPercStyles} colSpan="3">
              Deal Analysis Total
            </td>
            <td style={tdCurrencyStyle}>
              {currencyFormat(cs.Deal_Analysis_GP, 2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CSCostingInfo;

const tableStyles = {
  color: "#333",
  width: "100%",
  border: "1px solid #cccccc",
  borderCollapse: "collapse",
  marginTop: "10px",
};

const thStyles = {
  border: "1px solid #cccccc",
  backgroundColor: "#f3f3f3",
};

const tdDescStyles = {
  border: "1px solid #cccccc",
  width: "40%",
  paddingLeft: "10px",
};

const tdCurrencyStyle = {
  border: "1px solid #cccccc",
  textAlign: "right",
  paddingRight: "10px",
};

const tdTotalsStyles = {
  border: "1px solid #cccccc",
  textAlign: "right",
  paddingRight: "10px",
  fontWeight: "bold",
};

const tdTotalPercStyles = {
  border: "1px solid #cccccc",
  paddingRight: "10px",
  textAlign: "right",
};

const extrasContainer = {
  marginTop: "5px",
  marginBottom: "5px",
  width: "98%",
  border: "1px solid #cccccc",
  borderCollapse: "collapse",
};

const extraNameTDStyle = {
  border: "1px solid #cccccc",
  backgroundColor: "#f9f9f9",
  width: "45%",
};

const extraTDStyle = {
  border: "1px solid #cccccc",
  backgroundColor: "#f9f9f9",
  textAlign: "right",
  paddingRight: "10px",
};
