import axios from "axios";

export const getCostingSheet = (costingSheetID) => {
	console.log("GET Costing Sheet: " + costingSheetID);
	// const promise = new Promise((resolve, reject) => {
	//     resolve({
	//         costingSheetID,
	//         Test: "True"
	//     })
	// })
	// return promise
	return axios.get(
    `https://crmapi.heycarter.co.za/api/Costingsheet?UID=${costingSheetID}`
  );
};
