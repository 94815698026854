import CostingSheet from "./components/costingsheet/CostingSheet"
import {getCostingSheet} from "./api"

const routes = [
    {
        path: '/costingsheet/:id',
        exact: true,
        name: 'costingsheet',
        component: CostingSheet,
        fetchInitialData: (path = '') => getCostingSheet(path.split('/').pop())
    },
]

export default routes