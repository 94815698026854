import React, { useEffect, useState } from "react";
import CSCostingInfo from "./CSCostingInfo";
import CSCustomerInfo from "./CSCustomerInfo";
import CSHeader from "./CSHeader";
import { getCostingSheet } from "../../api";
import headerLogo from "../../images/Hey-Carter-Logo-about.png";

const CostingSheet = ({ match }) => {
	const [costingSheet, setCostingSheet] = useState();
	const [loading, setLoading] = useState(true);
	const [emptyCostingSheet, setEmptyCostingSheet] = useState(false);

	useEffect(() => {
		getCostingSheet(match.params.id).then((response) => {
			console.log(response);
			if (response.data === "No Costing sheet found") {
				setEmptyCostingSheet(true);
			} else {
				setCostingSheet(response.data);
			}
			setLoading(false);
		});
	}, []);

	// useEffect(() => {
	//     if (costingSheet) {
	//         console.log(costingSheet)
	//     }
	// },[costingSheet])

	return (
		<div className="page" style={pageContainerStyles}>
			{loading ? (
				<div>Loading...</div>
			) : (
				<React.Fragment>
					{emptyCostingSheet ? (
						<div>
							<div style={logoStyles}>
								<img
									width="98"
									height="85"
									src={headerLogo}
									alt="Carter Logo"
								/>
							</div>
							<p>The costing does not exist in the Carter DB yet.</p>
							<p>
								please use the print preview method to generate this costing
								sheet.
							</p>
						</div>
					) : (
						<div className="document" style={documentStyles}>
							<CSHeader costingSheet={costingSheet} />
							<CSCustomerInfo costingSheet={costingSheet} />
							<CSCostingInfo costingSheet={costingSheet} />

							<div>
								<h4>Gift Summary</h4>
								<span>
									{costingSheet.Gift_Summary
										? costingSheet.Gift_Summary
										: "N/A"}
								</span>
								<h4>Recon Summary</h4>
								<span>
									{costingSheet.Recon_Items_Summary
										? costingSheet.Recon_Items_Summary
										: "N/A"}
								</span>
							</div>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

export default CostingSheet;

const pageContainerStyles = {
	display: "flex",
	justifyContent: "center",
	paddingTop: "20px",
	color: "#636363",
	fontSize: "0.9em",
};

const documentStyles = {
	width: "100%",
	maxWidth: "800px",
	border: "1px solid #cccccc",
	padding: "20px",
	alignItems: "center",
};

const logoStyles = { alignItems: "center" };
