import React from "react"


const CSCustomerInfo = ({costingSheet}) => {
    return (
      <div>
        <table style={tableStyles}>
          <tbody>
            <tr>
              <td style={titleStyle}>Customer:</td>
              <td style={fieldStyle}>{costingSheet.Deal_Contact_Name}</td>
              <td style={titleStyle}>Finance House:</td>
              <td style={fieldStyle}>{costingSheet.Finance_House}</td>
              <td style={titleStyle}>Model:</td>
              <td style={fieldStyle}>{costingSheet.Deal_Car}</td>
            </tr>
            <tr>
              <td style={titleStyle}>Stock No:</td>
              <td style={fieldStyle}>{costingSheet.Stock_No}</td>
              <td style={titleStyle}>Year:</td>
              <td style={fieldStyle}>{costingSheet.Deal_Year}</td>
              <td style={titleStyle}>Transferring Agent:</td>
              <td style={fieldStyle}>{costingSheet.Transferring_Agent}</td>
            </tr>
            <tr>
              <td style={titleStyle}>Sales Exec:</td>
              <td style={fieldStyle}>{costingSheet.Sales_Executive}</td>
              <td style={titleStyle}>Sales Manager:</td>
              <td style={fieldStyle}>{costingSheet.Sales_Manager}</td>
              <td style={titleStyle}>Created Date:</td>
              <td style={fieldStyle}>{costingSheet.Created_Date}</td>
            </tr>
            <tr>
              <td style={titleStyle}>Delivery Date:</td>
              <td style={fieldStyle}>{costingSheet.Deal_Delivery_Date}</td>
              <td style={titleStyle}>Status:</td>
              <td style={fieldStyle}>{costingSheet.Status}</td>
              <td style={titleStyle}>Approved Date:</td>
              <td style={fieldStyle}>{costingSheet.Approved_Date}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
}

export default CSCustomerInfo

const tableStyles = {
    width: "100%",
    border: "1px solid #cccccc",
    borderCollapse: "collapse",
    marginTop: "10px"
}

const titleStyle = {
    border: "1px solid #cccccc",
    fontWeight: "bold",
    textAlign: "right",
    paddingRight: "10px",
    width: "10%"
}

const fieldStyle = {
    border: "1px solid #cccccc",
    width: "23%",
    paddingLeft: "10px",
}