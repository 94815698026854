import React, {Fragment} from "react"
import ManOnHorse from "./../images/CarterManHorse.png"

const NoMatch = () => {    
    return(
        <Fragment>
            <div style={pageContainerStyle}>                
                <div style={notFoundContainerStyle}>
                    <div style={notFoundTextStyle}>
                        <h2>404 Not Found</h2>
                        <img style={manOnHorseStyle} src={ManOnHorse} alt="Carter Man On Horse" />
                        <p>This is not the page you are looking for...</p>
                    </div>
                </div>
            </div>            
        </Fragment>
    )    
}

export default NoMatch

const pageContainerStyle = {
    paddingLeft:'2%',
    paddingRight:'2%'
}

const notFoundContainerStyle = {
    display:'flex',
    justifyContent:'center',
    alignItems: 'center',
    height:'80vh'
}

const notFoundTextStyle = {
    textAlign:'center'
}

const manOnHorseStyle = {
    width:'150px'
}